import { BaseProjectsProps } from "@custom-types/project-types";
import { ProjectHeaders } from "@components/table/projects/projects-table-utils";
import { useMediaQueryList } from "@hooks/use-media-query";
import { useEffect } from "react";
import { resetTableState, setTableType } from "@store/table/table-slice";
import { ProjectEvents, TableType } from "@utils/track-event/track-event-list";
import { useAppDispatch } from "@store/store-helper";
import { ProjectBulkButtonComponents } from "@components/common/faro-table/faro-table-types";
import { getProjectsColumns } from "@components/table/projects/projects-columns";
import { FaroTable } from "@components/common/faro-table/faro-table";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { useTrackEvent } from "@utils/track-event/use-track-event";

export type ProjectTableContextType =
  | "active projects"
  | "archived projects"
  | "group projects";

interface Props extends BaseCompanyIdProps, BaseProjectsProps {
  /** Whether the data for showing a table is loading or not */
  isLoading: boolean;

  /** Recognizes on which context the table is being used */
  contextType: ProjectTableContextType;

  /** All the columns that we want to show. The order defines the order of the columns */
  requiredColumns: ProjectHeaders[];

  /** List of all the bulk actions and callbacks */
  bulkActionButtons?: ProjectBulkButtonComponents;
}

/**
 * Listing all the projects of a subject such as a company or a group in a table
 */
export function ProjectsTable({
  companyId,
  projects,
  isLoading,
  contextType,
  requiredColumns,
  bulkActionButtons,
}: Props): JSX.Element {
  const { navigateToProjectDetail } = useAppNavigation();
  const { canViewAllCompanyGroups } = useHasUserValidRoleCompanyLevel();
  const dispatch = useAppDispatch();
  const { isMedium, isScreenLgAndLarger } = useMediaQueryList();
  const { trackEvent } = useTrackEvent();

  // Make sure the correct table type is set in the store and reset the bulk action state after unmounting
  useEffect(() => {
    // As ProjectsTable is a component that is used in multiple places,
    // we need to make sure that the state is reset after contextType is changed
    dispatch(resetTableState());
    switch (contextType) {
      case "active projects":
        dispatch(setTableType(TableType.activeProjects));
        break;

      case "archived projects":
        dispatch(setTableType(TableType.archiveProjects));
        break;

      case "group projects":
        dispatch(setTableType(TableType.groupProjects));
        break;
    }

    return () => {
      dispatch(resetTableState());
    };
  }, [contextType, dispatch]);

  return (
    <FaroTable
      entities={projects}
      uniqueIdKey={"id"}
      isLoading={isLoading}
      sx={{
        "& .MuiDataGrid-row": {
          cursor: "pointer",
        },
      }}
      columns={requiredColumns.map(
        (requiredColumn) =>
          getProjectsColumns({
            contextType,
            companyId,
            options: {
              // TODO: Naming will be fixed in https://faro01.atlassian.net/browse/ST-1348
              isScreenLgOrLarger: isScreenLgAndLarger,
              isMedium,
            },
            permissions: {
              canViewAllCompanyGroups,
            },
            trackEvent,
          })[requiredColumn]
      )}
      bulkActionButtons={bulkActionButtons}
      onRowClick={(id) => {
        trackEvent({
          name: ProjectEvents.openProjectDetails,
          props: {
            eventLocation: contextType,
          },
        });
        navigateToProjectDetail({
          companyId,
          projectId: id,
        });
      }}
      rowHeight={70}
    />
  );
}
