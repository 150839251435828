import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  fetchingProjectsFlagsSelector,
  hasFetchedFirstPageSelector,
  nextPageCursorSelector,
  filteredProjectsSelector,
  listTypeSelector,
} from "@store/projects/projects-selector";
import { useCoreApiClient } from "@api/use-core-api-client";
import { ProjectsPageTable } from "@pages/projects/projects-page-table";
import { dataViewModeSelector, searchSelector } from "@store/ui/ui-selector";
import { DataViewMode } from "@store/ui/ui-slice";
import { ProjectArchivingState } from "@custom-types/project-types";
import { ProjectsTiles } from "@pages/projects/projects-tiles";
import { ProjectsOverviewEmptyPage } from "@components/common/empty-page/projects-overview-empty-page";
import { ButtonEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { fetchProjects } from "@store/projects/projects-slice-thunk";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { MIN_CHARACTERS_FOR_SEARCH } from "@utils/project-utils";
import { resetProjectsList } from "@store/projects/projects-slice";

interface Props extends BaseCompanyIdProps {
  /** The archiving-state of the projects that will be shown */
  projectArchivingState: ProjectArchivingState;

  /**
   * Flag whether the content should be shown as loading regardless whether it was loaded.
   * This is useful when the parent component know that the content should be loading,
   * e.g. it is still loading the permissions.
   */
  shouldForceLoading: boolean;
}

/**
 * The projects tab listing all the active or archived projects (depending on the props) in the company
 */
export function ProjectsOverview({
  companyId,
  projectArchivingState,
  shouldForceLoading,
}: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const { debouncedSearchText, isDebouncingSearch } = useAppSelector(searchSelector);
  const { projectsView } = useAppSelector(dataViewModeSelector);
  const { isFetchingProjects } = useAppSelector(fetchingProjectsFlagsSelector);
  const projects = useAppSelector(filteredProjectsSelector(projectArchivingState));
  const listType = useAppSelector(listTypeSelector(projectArchivingState));
  const hasFetchedFirstPage = useAppSelector(hasFetchedFirstPageSelector(listType));
  const nextPageCursor = useAppSelector(nextPageCursorSelector(listType));

  /** Computes whether it is still loading to fetch all projects */
  const isLoading = useMemo(() => {
    return (
      isFetchingProjects ||
      // For projects search only show the loading state when user is typing and the current
      // search has the required minimum char length.
      (isDebouncingSearch && debouncedSearchText.length >= MIN_CHARACTERS_FOR_SEARCH) ||
      shouldForceLoading ||
      !hasFetchedFirstPage
    );
  }, [
    debouncedSearchText,
    isFetchingProjects,
    isDebouncingSearch,
    shouldForceLoading,
    hasFetchedFirstPage,
  ]);

  /** Flag whether the empty page should be shown, because it is no longer loading and does not have projects */
  const shouldShowEmptyPage = useMemo(() => {
    return !isLoading && hasFetchedFirstPage && projects.length === 0;
  }, [isLoading, hasFetchedFirstPage, projects]);

  /**
   * Flag whether the load more projects button should be hidden, either because there are no more projects to fetch,
   * or because there is something loading.
   */
  const shouldHideLoadMoreButton = useMemo(() => {
    return !nextPageCursor || isLoading;
  }, [isLoading, nextPageCursor]);

  // Fetches the first page of search projects whenever the debounced search text changes and the new value
  // has at least the required minimum length for searching projects.
  useEffect(() => {
    if (debouncedSearchText.length >= MIN_CHARACTERS_FOR_SEARCH) {
      dispatch(resetProjectsList("searched"));

      void dispatch(
        fetchProjects({
          coreApiClient,
          companyId,
          projectArchivingState,
          search: debouncedSearchText,
        })
      );
    }
  }, [companyId, coreApiClient, debouncedSearchText, dispatch, projectArchivingState]);

  /**
   * Fetches the next page of projects if the nextPageCursor is defined
   */
  const loadMoreProjects = useCallback(async (): Promise<void> => {
    if (nextPageCursor) {
      trackEvent({
        name: ButtonEvents.loadMore,
        props: {
          data: `${projectArchivingState} projects`,
        },
      });
  
      await dispatch(
        fetchProjects({
          coreApiClient,
          companyId,
          projectArchivingState,
          next: nextPageCursor,
          search: debouncedSearchText,
        })
      );
    }
  }, [companyId, coreApiClient, debouncedSearchText, dispatch, nextPageCursor, projectArchivingState, trackEvent]);

  // If fetching is done and projects is empty, then
  // either no project with selected archive state exist or no project with the searchText is available
  if (shouldShowEmptyPage) {
    return (
      <ProjectsOverviewEmptyPage
        projectArchivingState={projectArchivingState}
      />
    );
  }

  if (projectsView === DataViewMode.list) {
    return (
        <ProjectsPageTable
          projects={projects}
          companyId={companyId}
          projectStatus={projectArchivingState}
          isLoading={isLoading}
          shouldHideLoadMoreButton={shouldHideLoadMoreButton}
          loadMoreProjects={loadMoreProjects}
        />
    );
  } else {
    return (
      <ProjectsTiles
        projects={projects}
        companyId={companyId}
        isLoading={isLoading}
        shouldHideLoadMoreButton={shouldHideLoadMoreButton}
        loadMoreProjects={loadMoreProjects}
        projectStatus={projectArchivingState}
      />
    );
  }
}
