import { useCoreApiClient } from "@api/use-core-api-client";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { GroupDetailsInfoBar } from "@components/group-details/group-details-info-bar";
import { ProjectsTable } from "@components/table/projects/projects-table";
import { ProjectHeaders } from "@components/table/projects/projects-table-utils";
import { BaseGroupDetailsProps } from "@custom-types/group-types";
import { ProjectArchivingState } from "@custom-types/project-types";
import { useAppParams } from "@router/router-helper";
import {
  activeProjectsOfSelectedGroupSelector,
  fetchingProjectsFlagsSelector,
  nextPageCursorSelector,
} from "@store/projects/projects-selector";
import { fetchProjects } from "@store/projects/projects-slice-thunk";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useCallback, useEffect, useMemo } from "react";
import ProjectsDuoColorIcon from "@assets/icons/projects-duo-color.svg?react";
import { NotFoundPage } from "@pages/not-found-page";
import { ProjectsPageBulkActions } from "@pages/projects/projects-page-bulk-actions";
import { LoadMoreButton } from "@components/common/button/load-more-button";
import { APITypes } from "@stellar/api-logic";
import { convertProjectTypesToSdbProject } from "@store/projects/projects-slice-utils";
import { setAllProjects } from "@store/projects/projects-slice";

interface Props extends Partial<BaseGroupDetailsProps> {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/**
 * Contains the Projects tab of the group details
 */
export function GroupProjects({
  group,
  isLoading = false,
}: Props): JSX.Element {
  const { companyId } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const activeProjectsOfSelectedGroup = useAppSelector(activeProjectsOfSelectedGroupSelector);
  const { isFetchingProjects } = useAppSelector(fetchingProjectsFlagsSelector);
  const nextPageCursor = useAppSelector(nextPageCursorSelector("group"));

  /**
   * Flag whether the load more projects button should be hidden, either because there are no more projects to fetch,
   * or because the user is searching for projects.
   */
  const shouldHideLoadMoreButton = useMemo(() => {
    return !nextPageCursor || isFetchingProjects;
  }, [nextPageCursor, isFetchingProjects]);

  // Fetches the first page of active group projects
  // To update the stored projects we need to set all the fetched projects.
  const fetchFirstPage = useCallback(async (
    companyId: APITypes.CompanyId,
    groupId: APITypes.GroupId
  ): Promise<void> => {
    try {
      const response = await dispatch(
        fetchProjects({
          coreApiClient,
          companyId,
          groupId,
          projectArchivingState: ProjectArchivingState.active,
        })).unwrap();
      const projects = response.projects.map(convertProjectTypesToSdbProject);
      dispatch(setAllProjects(projects));
    } catch (_) {
      // The error slice will take care of logging and displaying the error to the user
    }
  }, [coreApiClient, dispatch]);

  // Fetches the first page of active group projects
  useEffect(() => {
    if (companyId && group?.id) {
      void fetchFirstPage(companyId, group.id);
    }
  }, [companyId, fetchFirstPage, group?.id]);

  if (!companyId) {
    return <NotFoundPage />;
  }

  let emptyPageContent = null;
  if (
    activeProjectsOfSelectedGroup.length === 0 &&
    !isFetchingProjects &&
    !isLoading
  ) {
    emptyPageContent = (
      <EmptyPage
        title="This group has no active projects"
        // eslint-disable-next-line max-len -- needed per design
        subtitle="You don't have any active projects connected to this group. Create a new project or add an existing active project to this group."
        icon={ProjectsDuoColorIcon}
      />
    );
  }

  const buttonComponents = ProjectsPageBulkActions({
    companyId,
    projects: activeProjectsOfSelectedGroup,
    projectStatus: ProjectArchivingState.active,
    subjectType: "group",
  });

  /**
   * Calls the backend to fetch more projects and increases the number of projects shown on the screen.
   */
  async function loadMoreProjects(): Promise<void> {
    if (companyId && nextPageCursor && group?.id) {
      await dispatch(
        fetchProjects({
          coreApiClient,
          companyId,
          groupId: group.id,
          projectArchivingState: ProjectArchivingState.active,
          next: nextPageCursor,
        })
      );
    }
  }

  return (
    <>
      <GroupDetailsInfoBar group={group} />
      {emptyPageContent ?? (
        <>
          <ProjectsTable
            companyId={companyId}
            projects={activeProjectsOfSelectedGroup}
            isLoading={isFetchingProjects || isLoading}
            contextType={"group projects"}
            requiredColumns={[
              ProjectHeaders.name,
              ProjectHeaders.managerAvatar,
              ProjectHeaders.projectManager,
              ProjectHeaders.lastUpdated,
              ProjectHeaders.accessLevel,
              ProjectHeaders.options,
            ]}
            bulkActionButtons={buttonComponents}
          />

          <LoadMoreButton
            isLoading={isLoading}
            buttonText="Load more projects"
            shouldHideLoadMoreButton={shouldHideLoadMoreButton}
            onClick={loadMoreProjects}
          />
        </>
      )}
    </>
  );
}
